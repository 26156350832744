<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="2" class="mr-2" v-if="returnAbility('cost-center:store')">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                <v-icon size="20">mdi-plus</v-icon>
                {{ $t('Add a cost center') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="d-flex justify-center card-title__heading">
                <h2> {{ $t('Add a cost center') }}</h2>
              </v-card-title>
              <v-card-text>
                <v-text-field :label="$t('name')" v-model="name" outlined dense color="#757575"
                  type="text"></v-text-field>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn color="green" class="white--text" @click="submitCostCenter"
                  :loading="submitBtnLoading">{{ $t('add') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-card elevation="0" class="pt-5">
      <app-base-table  :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" serverRoute="/accounting/cost-center"
        :enableDelete="displayDeleteIcon">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-cost-center v-if="returnAbility('cost-center:update')" :recordData="row"
            @recordUpdated="fetchData(1)"></app-edit-cost-center>
        </template>
      </app-base-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import editCostCenter from "./editCostCenter.vue";
export default {
  components: {
    appEditCostCenter: editCostCenter,
  },
  data() {
    return {
      dialog: false,
      submitBtnLoading: false,
      name: "",
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "costCenter/getTableOptions"
    }),
  },
  methods: {
    async submitCostCenter() {
      try {
        this.submitBtnLoading = true;
        await axios.post("/accounting/cost-center", {
          name: this.name,
        });
        this.$Notifications(
          this.$t('add success'),
          { timeout: 1000, rtl: true },
          "success"
        );
        this.dialog = false;
        this.name = "";
        this.fetchData(1);
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
    ...mapActions({
      fetchCostCenter: "costCenter/fetchCostCenter",
    }),
    fetchData(page) {
      this.fetchCostCenter({
        page,
      });
    },
  },
  created() {
    if (this.returnAbility("cost-center:delete")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>

</style>