<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('name')" v-model="name" outlined dense color="#757575" type="text"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="editRecord" :loading="editBtnLoading">{{ $t('edit')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.name = this.recordData.name;
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/accounting/cost-center/${this.recordData.id}`, {
          name: this.name,
        });
        this.$Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  created() {
    this.name = this.recordData.name;
  },
};
</script>

<style>

</style>